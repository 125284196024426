<template>
	<div id="index">
    <pageHeader></pageHeader>


    <div class="banner-warp" v-loading="loading">
      <el-carousel indicator-position="outside"  height="824px" :autoplay=true>
        <el-carousel-item v-for="(banner, bannerIndex) in banners" :key="bannerIndex">
          <div class="home-banner-item" :style="'background-image: url('+fileUrl+banner.newsThumbnail+')'"></div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="session session-ys bg-color-1">
      <div class="content-inner">
        <div class="session-h1">专注二手车交易运营</div>
        <div class="session-h2">核心优势  值得信赖</div>
        <ul>
          <li class="ys-li-1">
            <img src="../assets/images/youshi-1.png">
            <div class="ys-t-1">国企背景+行业头部</div>
            <div class="ys-t-2">强强联合——诚信.专业</div>
          </li>
          <li class="ys-li-2">
            <img src="../assets/images/youshi-2.png">
            <div class="ys-t-1">定制化服务</div>
            <div class="ys-t-2">经销商/集团定制二手车业务</div>
          </li>
          <li class="ys-li-3">
            <img src="../assets/images/youshi-3.png">
            <div class="ys-t-1">丰富运营经验</div>
            <div class="ys-t-2">高效、迅捷处置车辆</div>
          </li>
          <li class="ys-li-4">
            <img src="../assets/images/youshi-4.png">
            <div class="ys-t-1">一站式服务</div>
            <div class="ys-t-2">完整的二手车交易链生态圈</div>
          </li>
          <li class="ys-li-5">
            <img src="../assets/images/youshi-5.png">
            <div class="ys-t-1">专业化团队</div>
            <div class="ys-t-2">4S店+经销商管理<br>主机厂+评估人员</div>
          </li>
          <li class="ys-li-6">
            <img src="../assets/images/youshi-6.png">
            <div class="ys-t-1">高效处置</div>
            <div class="ys-t-2">20000+车商群体在线竞价</div>
          </li>
          <li class="ys-li-7">
            <img src="../assets/images/youshi-7.png">
            <div class="ys-t-1">车源优质</div>
            <div class="ys-t-2">正规4S店<br>
              经销商集团车源<br>
              主机厂回购车辆</div>
          </li>

        </ul>
      </div>
    </div>

    <div class="session session-server">
      <div class="content-inner">
        <div class="session-h1" style="color: #fff;">专业服务  放心托付</div>
        <div class="session-h2">诚信、专业、二手车一站式服务，我们提供多种服务解决方案，相信您一定会满意而归</div>
        <ul>
          <router-link to="/business/36/42">
            <li>
              <img src="../assets/images/jiejue-1.png">
              <div>评估检测</div>
              <span>1</span>
            </li>
          </router-link>
          <!--<li>
            <img src="../assets/images/jiejue-2.png">
            <div>机构车处置</div>
            <span>2</span>
          </li>-->
          <router-link to="/business/36/41">
            <li>
              <img src="../assets/images/jiejue-3.png">
              <div>专业咨询辅导</div>
              <span>2</span>
            </li>
          </router-link>
          <router-link to="/business/36">
            <li>
              <img src="../assets/images/jiejue-4.png">
              <div>机构车处置</div>
              <span>3</span>
            </li>
          </router-link>
          <router-link to="/business/36/40">
            <li class="last">
              <img src="../assets/images/jiejue-5.png">
              <div>二手车拍卖</div>
              <span>4</span>
            </li>
          </router-link>
          <li class="clear"></li>
        </ul>
      </div>
    </div>

    <div class="session session-about">
      <div class="content-inner">
        <div class="session-about-l">
          <img src="../assets/images/about-1.jpg">
        </div>
        <div class="session-about-r">
          <div class="session-h1">公司概况</div>
          <div class="session-h2">专业二手车交易运营服务商</div>
          <div class="session-about-content">
            <p>广州长佶科技有限公司属于广汽商贸与车易拍共同投资企业，成立于2014年12月，注册资本1500万。公司经营范围包含二手车拍卖、软件开发、信息系统集成服务、信息技术咨询服务、数据处理和存储服务等</p>
            <p>公司旨在为客户提供“二手车业务整体解决方案”，经过六年的发展，打造了一套适用于汽车主机厂、经销商集团、特约店的二手车运营模式，帮助4S店提升二手车置换率，促进新车销售。</p>
            <p>公司现有专业培训师队伍30余人，服务广汽集团旗下多个主机厂以及全国六百余多家特约店和八千余家二手车商，为主机厂提供咨询辅导和系统平台，帮助合作经销商打造长期有效的二手车业务体系，提升了置换率及经营利润。</p>
          </div>
          <div class="session-about-sp">
            服务品牌4S店逾<span>1000</span>家  累计完成销售量超<span>8.2</span>万台  查看详情
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>

    <div class="session session-map" >
      <div class="content-inner">
        <chartMap />
        <div class="map-desc">
          <div class="session-h1">业务覆盖范围</div>
          <div class="session-h2">专业二手车交易运营服务商</div>
          <div class="box-1">
            辐射省份 <strong>20+</strong>
          </div>
          <div class="box-1">
            覆盖主要城市 <strong>50+</strong>
          </div>
          <div class="box-1">
            加入车源商 <strong>20000+</strong>
          </div>
        </div>

      </div>
    </div>

    <div class="session session-case">
      <div class="content-inner">
        <div class="session-h1">我们的故事</div>
        <div class="session-h2">专业二手车交易运营服务商</div>
        <ul>
          <li>
            <img src="../assets/images/store-1.jpg">
            <div class="case-desc">
              <div class="case-desc-t">秦皇岛某汽车贸易有限公司</div>
              <div class="case-desc-b">作为一家新车销量低、资金周转困难、合作二手车车商少、目前资金周转困难的4S店，我希望二手车平台的快速检测功能符合厂家二手车评估标准，能接入更多的二手车车商、在线向车商询价、报价，在线多种拍卖交易方式、二手车业务数据可对接厂家系统，帮助我们完成厂家二手车置换的考核指标，获取厂家政策补贴，在提高二手车溢价能力的同时，高效率获得快速、合理的资金回笼。</div>
            </div>
          </li>
          <li>
            <img src="../assets/images/store-3.jpg">
            <div class="case-desc">
              <div class="case-desc-t">南京某销售服务有限公司-广本</div>
              <div class="case-desc-b" v-if="storeIndex=1">自从加入平台后，接触到买家更多了，也让我们店里置换回来的车辆流转速度大大加快，平台周到的服务也让我们安心。</div>
            </div>
          </li>
          <li>
            <img src="../assets/images/store-4.jpg">
            <div class="case-desc">
              <div class="case-desc-t">南京某销售服务有限公司—传祺</div>
              <div class="case-desc-b" v-if="storeIndex=2">和平台合作后，周转率的提升对店内资金积压压力得到了释放，而且买家活跃的报价也让车辆的价值得到了体现。感谢优车优盟</div>
            </div>
          </li>
          <li>
            <img src="../assets/images/store-2.jpg">
            <div class="case-desc">
              <div class="case-desc-t">李姓车商</div>
              <div class="case-desc-b" v-if="storeIndex=3">作为一个在广州人脉资源弱、资金少、拿车能力有限的二手车车商，我希望二手车平台进入的门槛低，注册流程简单，无需垫付高额的押金，平台信息费收取合理，拍卖方式公平透明，提供完整准确的检测报告，可以帮助我拿到真实可靠、价格合理、检测评估报告准确有保障的二手车。</div>
            </div>
          </li>
          <li>
            <img src="../assets/images/store-5.jpg">
            <div class="case-desc">
              <div class="case-desc-t">陈姓车商</div>
              <div class="case-desc-b" v-if="storeIndex=4">使用平台后，极大的扩大了车源的渠道，接触到更多优质保值的车型，对我的事业有了极大的帮助。</div>
            </div>
          </li>
          <li>
            <img src="../assets/images/store-6.jpg">
            <div class="case-desc">
              <div class="case-desc-t">于姓车商</div>
              <div class="case-desc-b" v-if="storeIndex=5">加入优车优盟后，让我惊讶的是它低于市场均价的服务费用，但服务也很周到，各项代办服务的提供也让我省不少心。</div>
            </div>
          </li>
          <li class="clear"></li>
        </ul>
      </div>
    </div>

    <div class="session session-partner">
      <div class="content-inner">
        <div class="session-h1">合作伙伴</div>
        <div class="session-h2">感谢您与我们的携手同行</div>
        <ul>
          <li v-for="(partner, index) in partners" :key="index">
            <img :src="fileUrl+partner.partnerImg">
          </li>
          <li class="clear"></li>
        </ul>
      </div>
    </div>

    <pageFooter></pageFooter>

	</div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
import pageFooter from "@/components/pageFooter";
import chartMap from "@/components/map";
import {getBanners, getPartners} from "@/api/banner";
import env from "@/env"

//import AOS from 'aos'
//import '../../node_modules/aos/dist/aos.css'

const fileUrl = env.fileUrl

export default {
  components: { pageHeader, pageFooter, chartMap},
	data() {
		return {
			loading: true,
      banners:null,
      fileUrl:fileUrl,
      stores:[
        {
          title:'秦皇岛某汽车贸易有限公司',
          desc:'作为一家新车销量低、资金周转困难、合作二手车车商少、目前资金周转困难的4S店，我希望二手车平台的快速检测功能符合厂家二手车评估标准，能接入更多的二手车车商、在线向车商询价、报价，在线多种拍卖交易方式、二手车业务数据可对接厂家系统，帮助我们完成厂家二手车置换的考核指标，获取厂家政策补贴，在提高二手车溢价能力的同时，高效率获得快速、合理的资金回笼。',
          img:'../assets/images/case-1.jpg',
        },
        {
          title:'南京某销售服务有限公司-广本',
          desc:'自从加入平台后，接触到买家更多了，也让我们店里置换回来的车辆流转速度大大加快，平台周到的服务也让我们安心。',
          img:'../assets/images/case-2.jpg',
        },
        {
          title:'南京某销售服务有限公司—传祺',
          desc:'和平台合作后，周转率的提升对店内资金积压压力得到了释放，而且买家活跃的报价也让车辆的价值得到了体现。感谢优车优盟',
          img:'../assets/images/case-3.jpg',
        },
        {
          title:'李姓车商',
          desc:'作为一个在广州人脉资源弱、资金少、拿车能力有限的二手车车商，我希望二手车平台进入的门槛低，注册流程简单，无需垫付高额的押金，平台信息费收取合理，拍卖方式公平透明，提供完整准确的检测报告，可以帮助我拿到真实可靠、价格合理、检测评估报告准确有保障的二手车。',
          img:'../assets/images/case-4.jpg',
        },
        {
          title:'陈姓车商',
          desc:'使用平台后，极大的扩大了车源的渠道，接触到更多优质保值的车型，对我的事业有了极大的帮助。',
          img:'../assets/images/case-5.jpg',
        },
        {
          title:'于姓车商',
          desc:'加入优车优盟后，让我惊讶的是它低于市场均价的服务费用，但服务也很周到，各项代办服务的提供也让我省不少心。',
          img:'../assets/images/case-6.jpg',
        },
      ],
      storeIndex:null,
      partners:[],
		};
	},
	mounted() {
    //AOS.init();
    this.getBanners()
    this.getPartners()
	},
	methods: {
    getBanners(){
      getBanners().then(response => {
        this.banners=response.data.list
        this.loading = false
      })
    },
    getPartners(){
      getPartners().then(response => {
        this.partners=response.data
      })
    },
	}
};
</script>

<style lang="scss" scoped>
.home-banner-item{
  height: 824px;
  background-position: center center;
  background-size: cover;
}

</style>
