import request from '@/utils/request'

//banner
export function getBanners(data) {
	return request({
		url: '/appnews/banner',
		method: 'get',
		data
	})
}


export function getPartners(data) {
	return request({
		url: '/apppartner/list',
		method: 'get',
		data
	})
}


