<template>
	<div id="map" style="width: 100%;  height:100%;"></div>
</template>

<script>
	import china from "@/lib/100000_full.json";
	const echarts = require("echarts");

export default {
	props:{
		apiUrl:{
			type:String,
		},
	},
	data() {
		return {
			//表对象
			charts:{},
			//统计数据
			timer: '',
			active:0,

		};
	},
	mounted() {
		this.charts = echarts.init(
			document.getElementById("map")
		);

		this.init();
	},
	methods: {
		init(){
			this.drawChart();

		},
		//画统计图
		drawChart() {
			this.charts.hideLoading();
			echarts.registerMap('china', china);

      var chinaDatas = [
        [{
          name: '北京',
          value: [116.407925,39.912916],
          ed: 1
        }],
        [{
          name: '天津',
          value: [117.210103,39.098048],
          ed: 1
        }],
        [{
          name: '河北',
          value: [114.523083,38.050247],
          ed: 1
        }],
        [{
          name: '山西',
          value: [112.547242,37.878692],
          ed: 1
        }],
        [{
          name: '内蒙古',
          value: [111.747022,40.849138],
          ed: 1
        }],
        [{
          name: '辽宁',
          value: [123.444505,41.823621],
          ed: 1
        }],
        [{
          name: '吉林',
          value: [125.349174,43.915719],
          ed: 1
        }],
        [{
          name: '黑龙江',
          value: [126.539438,45.813299],
          ed: 1
        }],
        [{
          name: '上海',
          value: [121.470479,31.236176],
          ed: 1
        }],
        [{
          name: '江苏',
          value: [118.803186,32.065553],
          ed: 1
        }],
        [{
          name: '浙江',
          value: [120.208117,30.252334],
          ed: 1
        }],
        [{
          name: '安徽',
          value: [117.226192,31.828344],
          ed: 1
        }],
        [{
          name: '福建',
          value: [119.304458,26.083238],
          ed: 1
        }],
        [{
          name: '江西',
          value: [115.865116,28.689042],
          ed: 1
        }],
        [{
          name: '山东',
          value: [117.127765,36.657025],
          ed: 1
        }],
        [{
          name: '河南',
          value: [113.635486,34.75392],
          ed: 1
        }],
        [{
          name: '湖北',
          value: [114.301012,30.600601],
          ed: 1
        }],
        [{
          name: '湖南',
          value: [112.939874,28.239775],
          ed: 1
        }],
        [{
          name: '广西',
          value: [108.370454,22.8226],
          ed: 1
        }],
        [{
          name: '重庆',
          value: [106.55585,29.570755],
          ed: 1
        }],
        [{
          name: '四川',
          value: [104.062827,30.577295],
          ed: 1
        }],
        [{
          name: '贵州',
          value: [106.653244,26.652344],
          ed: 1
        }],
        [{
          name: '云南',
          value: [102.850088,24.889355],
          ed: 1
        }],
        [{
          name: '陕西',
          value: [114.523083,38.050247],
          ed: 1
        }],
        [{
          name: '甘肃',
          value: [103.849435,36.068967],
          ed: 1
        }],
        [{
          name: '青海',
          value: [92.767471,35.598754],
          ed: 1
        }],
        [{
          name: '宁夏',
          value: [106.239448,38.494276],
          ed: 1
        }],
        [{
          name: '新疆',
          value: [87.616659,43.826194],
          ed: 1
        }],
        [{
          name: '广东省',
          value: [113.36995,22.976734],
          ed: 10
        }]
      ];

      var convertData = function(data) {
        var res = [];
        for(var i = 0; i < data.length; i++) {
          var dataItem = data[i];
          var fromCoord = dataItem[0].name;
          var toCoord = [113.280637,23.125178];
          if(fromCoord && toCoord) {
            res.push({
              coords: [toCoord, dataItem[0].value],
              lineStyle: {color: '#f4a307'},
            });
          }
        }
        return res;
      };

      var series = [];
      [['广东省', chinaDatas]].forEach(function(item) {
        series.push(

            {
              type: 'lines',
              coordinateSystem: 'geo',
              zlevel: 3,
              data: convertData(item[1]), //轨迹线条
              //polyline: true,
              lineStyle: {
                width: 0.5, //尾迹线条宽度
                opacity: 0.3, //尾迹线条透明度
                curveness: 0.5, //尾迹线条曲直度,
                color: '#ff0000'
              },
              effect: {
                show: true,
                period: 4, //箭头指向速度，值越小速度越快
                trailLength: 0.01, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: 'pin', //图标
                symbolSize: 5, //图标大小
              },
            },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              zlevel: 3,
              rippleEffect: { //涟漪特效
                period: 4, //动画时间，值越小速度越快
                brushType: 'stroke', //波纹绘制方式 stroke, fill
                scale: 7 //波纹圆环最大限制，值越大波纹越大
              },
              label: {
                show: true,
                position: 'right', //显示位置
                offset: [5, 0], //偏移设置
                formatter: function(params){//圆环显示文字
                  return params.data.name;
                },
                fontSize: 13,
                color: '#fff',
              },
              emphasis: {
                disabled: false
              },
              symbol: 'circle',
              symbolSize: function(val) {
                return 5+ val[2] * 5; //圆环大小
              },
              showEffectOn: 'render',
              /*itemStyle: {
                normal: {
                  show: false,
                  color: '#ff2345',
                  shadowBlur: 0,
                  shadowColor: '#ff2345'
                }
              },*/
              data: item[1].map(function(dataItem) {
                return {
                  name: dataItem[0].name,
                  value: dataItem[0].value.concat(0.7)
                };

              }),
            });
      });
      var option = {
        visualMap: {//板块区分颜色
          type: 'piecewise',
          show: false,
          pieces: [{
            min: 0.9,
            label: '建成',
            color: 'rgba(11,191,0,0.8)'
          },
            {
              max: 0.5,
              min: 0,
              label: '在建',
              color: 'rgba(191,197,53,0.8)'
            },
            {
              max: 0.9,
              min: 0.6,
              label: false,
              color: '#FF2345'
            }
          ],
          color: '#fff',
          textStyle: {
            color: '#fff',
          },
          visibility: 'off'
        },

        geo: [
          //地图背景
          /*{
            map: 'china',
            label: {
              show: false
            },
            roam: false,
            left: 'center',
            top: 65,
            bottom: 1,
            itemStyle: {
              areaColor: '#302e22',
              borderColor: '#302e22'
            },
            silent: true,
            zoom:1.3,
            center:[106.948024,32.563161],
          },
          {
            map: 'china',
            label: {
              show: false
            },
            roam: false,
            left: 'center',
            top: 62,
            bottom: 4,
            itemStyle: {
              areaColor: '#302e22',
              borderColor: '#302e22'
            },
            silent: true,
            zoom:1.3,
            center:[106.948024,32.563161],
          },
          {
            map: 'china',
            label: {
              show: false
            },
            roam: false,
            left: 'center',
            top: 59,
            bottom: 7,
            itemStyle: {
              areaColor: '#302e22',
              borderColor: '#302e22'
            },
            silent: true,
            zoom:1.3,
            center:[106.948024,32.563161],
          },
          {
            map: 'china',
            label: {
              show: false
            },
            roam: false,
            left: 'center',
            top: 56,
            bottom: 10,
            itemStyle: {
              areaColor: '#302e22',
              borderColor: '#302e22'
            },
            silent: true,
            zoom:1.3,
            center:[106.948024,32.563161],
          },
          {
            map: 'china',
            label: {
              show: false
            },
            roam: false,
            left: 'center',
            top: 53,
            bottom: 13,
            itemStyle: {
              areaColor: '#302e22',
              borderColor: '#302e22'
            },
            silent: true,
            zoom:1.3,
            center:[106.948024,32.563161],
          },*/
          //地图坐标
          {
            map: 'china',
            label: {
              show: false,
              tcolor: '#342e21',
              fontSize: 12
            },
            roam: false,
            top: 100,
            bottom: 16,
            itemStyle: {
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 1,
                colorStops: [{
                  offset: 0, color: '#302c23' // 0% 处的颜色
                }, {
                  offset: 1, color: '#03101a' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              },
              borderColor: '#6e7166',
              borderWidth:1
            },
            emphasis: {
              itemStyle: {
                areaColor: 'rgba(0,66,147,.8))'
              },
              label: {
                color: '#fff',
              }
            },
            zoom:1.6,
            center:[106.948024,32.563161],
          }],
        series: series
      };

			this.charts.setOption(option);
			// 响应式变化
			window.addEventListener("resize", () => this.charts.resize(), false);
		},
	},
	destroyed() {
		window.onresize = null;
	}
};
</script>

<style lang="scss" scoped>
</style>
